/**
* Adobe Target Performance extension for at.js
* @version 0.0.1
* @author Vadym Ustymenko - ACS, Adobe Systems Inc.
* @requires at.js 2.x+, https://unpkg.com/web-vitals@1.1.2/dist/web-vitals.js [unpkg.com], https://unpkg.com/web-vitals@1.1.2/dist/polyfill.js [unpkg.com]
* @use TBD
*/
var init = function () {

    (function(win, doc, opts){
 
        const perf = win.performance;
       
        if(typeof perf === "undefined")
          return;
       
        let results = [],          
            didCollectRsrcEntries = false;
       
        const atEvents = {
          //libraryLoaded: "at-library-loaded",
          requestStart: "at-request-start",
          requestSucceeded: "at-request-succeeded",
          requestFailed: "at-request-failed",
          contentRenderingStart: "at-content-rendering-start",
          contentRenderingSucceeded: "at-content-rendering-succeeded",
          contentRenderingFailed: "at-content-rendering-failed"
        }
       
        const atVitals = {
          launchLibLoad: "LLL", // Launch Library Load - measures resource start time to response end for Launch library or any JavaScript file that delivers VisitorAPI.js and at.js
          targetNetworkCall: "TNC", // Target Network Call - measures resource start time to response end for Target Delivery API call
          targetEventRequestSucceeded: "ATRS", // Adobe Target Request Succeeded - measures difference between Request start and Request succeeded events, which includes a network call plus additional processing
          targetEventRequestFailed: "ATRF", // Adobe Target Request Failed - measures difference between Request start and Request failed events, which includes a network call plus additional processing
          targetEventContentSucceeded: "ATCS", // Adobe Target Content Succeeded - measures difference between content rendering start and content rendering succeeded events
          targetEventContentFailed: "ATCF" // Adobe Target Content Failed - measures difference between content rendering start and content rendering failed events
        }
       
        const defaults = {
          launchPath: "//assets.adobedtm.com/launch-",
          deliveryPath: ".tt.omtrdc.net",
          callback: function(){}
        }
       
        const options = Object.assign({}, defaults, opts);
       
        const getDiffInMs = (start, end) => {
          return (end - start).toFixed(0);
        };
       
        const getTimestamp = function(name){
          return (perf.getEntriesByName(name).length > 0) ?
            perf.getEntriesByName(name)[0].startTime : 0;
        };
       
        const saveResults = function(name, startEntryName, endEntryName){
          const start = getTimestamp(startEntryName);
          const end = getTimestamp(endEntryName);
          const diff = getDiffInMs(start, end);
          results.push({[name]: diff});
          if(typeof options.callback === "function") options.callback();
        };
       
 
       
        // Get Resource Timing entries
        const collectResourceEntries = () => {
          if (didCollectRsrcEntries === true) return;
          const resourceListEntries = perf.getEntriesByType("resource");
          resourceListEntries.forEach(resource => {
            if (resource.initiatorType.indexOf('script')!==-1 && resource.name.indexOf(options.launchPath)!==-1) {
              const diff = getDiffInMs(resource.startTime, resource.responseEnd);
              results.push({[atVitals.launchLibLoad]: diff});
            }else if(resource.initiatorType.indexOf('xmlhttprequest')!==-1 && resource.name.indexOf(options.deliveryPath)!==-1){
              const diff = getDiffInMs(resource.startTime, resource.responseEnd);
              results.push({[atVitals.targetNetworkCall]: diff});
            }
          });
          didCollectRsrcEntries = true;
        };
       
        const subscribeToAtEvetns = () => {
          // doc.addEventListener(atEvents.libraryLoaded, function(e) { }, false),
          doc.addEventListener(atEvents.requestStart, function() {
            perf.mark(atEvents.requestStart);
          }, false),
          doc.addEventListener(atEvents.requestSucceeded, function() {
            perf.mark(atEvents.requestSucceeded);
            saveResults(atVitals.targetEventRequestSucceeded, atEvents.requestStart, atEvents.requestSucceeded);
            collectResourceEntries();
          }, false),
          doc.addEventListener(atEvents.requestFailed, function() {
            perf.mark(atEvents.requestFailed);
            saveResults(atVitals.targetEventRequestFailed, atEvents.requestStart, atEvents.requestFailed);
            collectResourceEntries();
          }, false),
          doc.addEventListener(atEvents.contentRenderingStart, function() {
            perf.mark(atEvents.contentRenderingStart);
          }, false),
          doc.addEventListener(atEvents.contentRenderingSucceeded, function() {
            perf.mark(atEvents.contentRenderingSucceeded);
            saveResults(atVitals.targetEventContentSucceeded, atEvents.contentRenderingStart, atEvents.contentRenderingSucceeded);
          }, false),
          //doc.addEventListener("at-content-rendering-redirect", function(e) { }, false),
          //doc.addEventListener("at-content-rendering-no-offers", function(e) { }, false),
          doc.addEventListener(atEvents.contentRenderingFailed, function() {
            perf.mark(atEvents.contentRenderingFailed);
            saveResults(atVitals.targetEventContentFailed, atEvents.contentRenderingStart, atEvents.contentRenderingFailed);
          }, false);
        }
       
        subscribeToAtEvetns();
       
        // Get Navigation Timing entries:
        //const navigationEntries = perf.getEntriesByType("navigation")[0]; // returns an array of a single object by default so we're directly getting that out.
       
        const getResults = () => {
          return results;
        };
       
        // Global scope
        win.getAtPerformance = getResults;
       
      })(window, document, {launchPath: "/sync.js", callback: function(){
        // if(typeof digitalData === "object"){
        //   digitalData.atPerformance = window.getAtPerformance()
        // }
      } });

};

export {
    init
};