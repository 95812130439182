var options = {
	site: "b2consumer-desktop",
	client: "homedepot",
	publishPath: "prod"
};

var getPublishPath = function () {

	var publishPath = "";

	if ( window.Bootstrapper && Bootstrapper.ensightenOptions && Bootstrapper.ensightenOptions.publishPath ) {
		publishPath = Bootstrapper.ensightenOptions.publishPath;
	}
	else if ( options.publishPath ) {
		publishPath = options.publishPath;
	}

	return publishPath;

};

var isProdBootstrap = ( function () {

	var pubPath = getPublishPath();

	if ( pubPath === 'prod' || pubPath === 'b2bprod' || pubPath === 'b2bbeta' ) {
		return true;
	}

	return false;

}() );

var getMCMID = function () {

	var markCookie = decodeURIComponent( document.cookie );

	markCookie = markCookie.split( ';' ).filter( function ( item ) {
		return ( item.indexOf( 'AMCV_F6421253512D2C100A490D45@AdobeOrg=' ) > -1 );
	} );

	var markCookSplit = ( markCookie.length ? markCookie[ 0 ].split( "MCMID|" ) : "" );
	var mcmid = ( markCookie && markCookSplit && markCookSplit[ 1 ] && markCookSplit[ 1 ].split( "|" )[ 0 ] ? markCookSplit[ 1 ].split( "|" )[ 0 ] : "" );

	return mcmid;

};

var init = function () {

	// set PROD or DEV ID
	window._pxAppId = ( isProdBootstrap ? 'PXJ770cP7Y' : 'PX04KmKlXI' );

	// Register Async Event for hashed score
	window[ window._pxAppId + '_asyncInit' ] = function ( px ) {
		px.Events.on( 'score', function ( score ) {
			// Set your action for the score here - can go to another variable or function. In this case we just log it to the console.
			try {
				_T.pxbs = score;
			}
			catch ( e ) {
				console.log( "ANALYTICSLOG perimeterx error: ", e );
			}
		} );

		//enrichment function
		px.Events.on( 'enrich', function ( value ) {
			// Set your action for the score here - can go to another variable or function. In this case we just log it to the console.
			try {
				const base64Data = ( typeof value === "string" && value.split ) ? value.split( ":" )[ 1 ] : null; // split value to get the base64 encoded data
				if ( base64Data ) {
					const dataStr = atob( base64Data ); // base64 decode the enrichment data
					const data = JSON.parse( dataStr ); // get the data as JSON
					_T.pxEnrich = data.inc_id;
				}
			}
			catch ( e ) {
				console.log( "ANALYTICSLOG perimeterx error: ", e );
			}
		} );

	};

	/** Passing adobe MCMID as second parameter to perimeterx **/
	window._pxParam2 = getMCMID();

	/**
	 * going to pass '1' to px as the 3 param if the customer lands on the confirmation page for better tracking
	 */
	if ( location.pathname === "/mycheckout/thankyou" ) {
		window._pxParam3 = '1';
	}

	var p = document.getElementsByTagName( "script" )[ 0 ],
		s = document.createElement( "script" );
	s.async = 1;
	s.src = "//client.px-cloud.net/" + window._pxAppId + "/main.min.js";
	p.parentNode.insertBefore( s, p );


};

export {
	init
}
