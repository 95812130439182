let options = {
	site: "b2consumer-desktop",
	client: "homedepot",
	publishPath: "prod"
};


const cookieRead = function ( e ) {
	e = encodeURIComponent( e );
	let t = ( ";" + document.cookie ).split( " " ).join( ";" ),
		n = t.indexOf( ";" + e + "=" ),
		i = n < 0 ? n : t.indexOf( ";", n + 1 );
	return n < 0 ? "" : decodeURIComponent( t.substring( n + 2 + e.length, i < 0 ? t.length : i ) )
};


/**
 * Determine what Host the Analytics platform is being provided through
 * @returns {string}: 'GCP' - Google GDN, 'AKA' - Akamai CDN, 'ENS' - Ensighten CDN,  '' - unknown
 */
const getAnalyticsHost = function (){
 
    let scripts = document.scripts, 
        script = '';

    for ( let i = 0; i < scripts.length; i++ ) {

        script = (typeof scripts[i].src === 'string' ? scripts[i].src : '');

        if (script.indexOf("assets.homedepot-static.com/analytics/") > -1 ){
            return 'GCP';
        }
        else if (script.indexOf("assets.thdstatic.com/analytics/") > -1 ){
            return 'AKA';
        }
        else if ( script.indexOf("nexus.ensighten.com/") > -1 ) {
            return 'ENS';
        }
   
    }
    
    return '';    
};


const getBusinessType = function () {

	let isb2x = isB2X();

	if ( options.site.indexOf( 'b2business' ) > -1 ) {
		return 'b2b';
	} else if ( isb2x ) {
		return 'b2x';
	} else {
		return 'b2c';
	}

};


const getClient = function () {

	let client = "";

	if ( window.Bootstrapper && Bootstrapper.ensightenOptions && Bootstrapper.ensightenOptions.client ) {
		client = Bootstrapper.ensightenOptions.client;
	}
	else if ( options.client ) {
		client = options.client;
	}

	return client;

};


const getCookieKeySVOCID = function ( cookieName ) {

	return ( cookieName === "THD_CUSTOMER" ? "t" : "svocCustomerAccountId" );
	
};


const getDeviceType = function () {

	let btstrap = getClient();

	if ( isConsumerApp() ) {
		return 'consumer app';
	}
	else if ( btstrap === 'homedepot' ) {
		return 'desktop';
	}
	else if ( btstrap === 'homedepotmobile' ) {
		return 'mobile';
	}
	else {
		return btstrap;
	}
};


const getPublishPath = function () {

	let publishPath = "";

	if ( window.Bootstrapper && Bootstrapper.ensightenOptions && Bootstrapper.ensightenOptions.publishPath ) {
		publishPath = Bootstrapper.ensightenOptions.publishPath;
	}
	else if ( options.publishPath ) {
		publishPath = options.publishPath;
	}

	return publishPath;

};


const getStoreNumber = function () {
	
	let storeID = "",
		cookieString = cookieRead( "THD_LOCALIZER" ),
		cookieObj = '',
		cookieArray = [];

	// exit early if no cookie found		
	if ( !cookieString ) return storeID;
		
	cookieObj = JSON.parse( decodeURIComponent( cookieString ) );

	if ( cookieObj.THD_LOCSTORE ) {

		cookieArray = cookieObj.THD_LOCSTORE.split("+");
		
		storeID = ( Array.isArray( cookieArray ) && cookieArray.length ? cookieArray[0] : "" );

	}

	return storeID;
	
};


const getSVOCID = function () {

	let cookieInfo = getUserCookie();

	//exit early if no cookie found
	if ( !cookieInfo ) { return ""; }

	let decodedCookieObject = decodeBase64Cookie( cookieInfo.value, "." );

	return ( decodedCookieObject ? decodedCookieObject[ getCookieKeySVOCID( cookieInfo.name ) ] : "" );

};


const getUserCookie = function () {
	
	let customerCookie = cookieRead( "THD_CUSTOMER" ),
		userCookie = null;

	if ( customerCookie ) {			

		return { "name": "THD_CUSTOMER", value: customerCookie } 

	} 

	userCookie = cookieRead( "THD_USER" );
	
	if ( userCookie ) { 

		return { "name": "THD_USER", value: userCookie } 
	}

	return null;

};


const isB2X = function () {

	let cookie = cookieRead( "THD_CUSTOMER" ),
		cookieValue = "",
		result = "";
	
	if ( cookie ) {
		cookie =  cookie.split('.');
		
		if ( Array.isArray( cookie ) ) {
			cookieValue = ( cookie.length === 3 ? cookie[1] : cookie[0] );
		}

	}	

	try {

		if ( cookieValue !== "" ) {		
			result = JSON.parse( window.atob( cookieValue ) );
			return ( typeof (result) !== "undefined" && result.c === 'B2X' ? true : false );
		}

	} catch (err) {
		return false;
	}

	return false;
};
const isConsumerApp = function () {

	let hasUA = window.navigator.userAgent.toLowerCase().indexOf("thdconsumer/") !== -1,
		hasCookie = document.cookie.indexOf( 'E4%3DCNative' ) !== -1;

	return hasUA || hasCookie;
};

const isPEP = function () {

	return window.location.pathname.indexOf("/pep/") !== -1; 

};

const isPIP = function () {

	return window.location.pathname.indexOf("/p/") !== -1; 

};

const isSearchPage = function () {

	return window.location.pathname.indexOf("/s/") !== -1; 
	
};

let isUserPersisted = () => {

	try {

		const parsedJwt = decodeBase64Cookie( cookieRead( "THD_CUSTOMER" ) ) || decodeBase64Cookie( cookieRead( "THD_USER_SESSION") );

		if ( !parsedJwt ) {

			return false;

		} else {

			const expirationMilliseconds = (parsedJwt.k === 'y') ? 7776000000 : 7200000; // 90d for kmsi, 2hr for non-kmsi
			const jwtTimestamp = parsedJwt.v;
			const currentTimestamp = (new Date()).getTime();
			
			return (currentTimestamp - jwtTimestamp) > expirationMilliseconds;

		}
	} catch ( error ) {

		console.error( "Error with isUserPersisted: ", error );
		window._T.errors.push( error );

		return "";	
	}	

};

const decodeBase64Cookie = function ( encodedCookieValue, separator = "." ) {

	try {
		
		let encodedSubstring = encodedCookieValue.split( separator )[ 0 ];
		let regexPattern = /"/g;
		let cleanedEncodedSubstring = encodedSubstring.replace( regexPattern, '' );
		let decodedCookieValue = atob( cleanedEncodedSubstring );
		let cookieObject = ( typeof decodedCookieValue === "string" && decodedCookieValue ? JSON.parse( decodedCookieValue ) : "" );

		return cookieObject;

	}
	catch ( error ) {

		console.error( "Base64 Decoding Cookie Error: ", error );
		window._T.errors.push( error );

		return "";

	}

};

export {
	cookieRead,
	decodeBase64Cookie,
	getAnalyticsHost,
	getBusinessType,		
	getClient,
	getCookieKeySVOCID,
	getDeviceType,
	getPublishPath,	
	getStoreNumber,
	getSVOCID,
	getUserCookie,
	isB2X,	
	isConsumerApp,
	isPEP,
	isPIP,
	isSearchPage,	
	isUserPersisted	
};
