import * as at from "./at";
import * as atperf from "./atperf";
import * as visitorAPI from "./visitorAPI";
import * as perimeterx from "./perimeterx";
import { getAnalyticsHost, getSVOCID } from "./utilities";

( function () {
	// An indicator for build_local mappings 
	
	console.log( '%c ANALYTICSLOG -- Local Build Mapping: Sync | ' + CODE_VERSION + ' | ' + BUILD_TARGET + ' | 2024-03-25T16:21:34.607Z', 'background: orange; color: white; display: block; line-height:20px; padding:5px 30px; border: 1px solid red; font-size:1em;' );

	window._T = ( window._T ? _T : {} );
	window._T.deployments = ( _T.deployments ? _T.deployments : {} );

    window.digitalData = ( window.digitalData ? digitalData : { event: [] } );

    if ( BUILD_TARGET === "blinds" || BUILD_TARGET === "blindsca" || BUILD_TARGET === "justblinds" || BUILD_TARGET === "americanblinds" ) {
        digitalData.event = ( digitalData.event && digitalData.event.constructor === Array ? digitalData.event : [] );
    }

    window._dD = window.digitalData;

    _T.pageData = ( _T.pageData ? _T.pageData : [] );
    _T.eventData = ( _T.eventData ? _T.eventData : [] );

	let visitorAPIisFromSync = false;

	var isOverlay = function () {

		const _PATH = window.location.pathname.toLowerCase(),
			isMyCartOverlay = ( _PATH === '/mycart/overlay' && window.location !== window.parent.location ),
			isNucleusCartOverlay = ( _PATH.indexOf('/cart/') !== -1 && window.location !== window.parent.location );

		return ( isMyCartOverlay || isNucleusCartOverlay );

	};

	if ( !window.Visitor ) {
		atperf.init();
		visitorAPI.init();
		visitorAPIisFromSync = true;
	}

    if ( BUILD_TARGET === "b2consumer-desktop" || BUILD_TARGET === "b2consumer-mobile" || BUILD_TARGET === "b2business-desktop" || BUILD_TARGET === "b2business-mobile" || BUILD_TARGET === "b2x-desktop" || BUILD_TARGET === "b2x-mobile" ) {

		//Grab SVOCID here for easy use throughout Request lifecycle 
		_T.svocid = getSVOCID();

        /**
         * List values (array) within a given object, no matter level of JSON complexity
         * @param objData
         * @param out - can pass in an empty array or nothing at all if no matchKey
         * @param matchKey optional string value, if found, will return all values of that key found
         * @returns {*|Array}
         * examples:
         *   _T.getAllValues(digitalData.transaction,[],'sku')
         *       > this returns all values of keys named "sku" in the transaction node of digitalData
         *   _T.getAllValues(digitalData)
         *       > this returns all values of all keys in digitalData object (used to see if any have values assigned (digitalData in use)
         */
        _T.getAllValues = function( objData, out = [], matchKey = '' ) {

            var keyVal;

            for ( var key in objData ) {
                keyVal = objData[ key ];

                var nodetype = typeof ( objData[ key ] );
                if ( nodetype === 'object' || nodetype === 'array' ) {
                    out = _T.getAllValues( objData[ key ], out, matchKey );
                } else {
                    if ( keyVal !== undefined && typeof ( keyVal ) !== "function" ) {
                        if ( matchKey === '' || matchKey && matchKey === key ) {
                            out.push( keyVal );
                        }
                    }
                }
            }
            return ( out );
        };

		
		_T.mboxEnabled = ( function () {

			let isConsumerApp = document.cookie.indexOf( 'E4%3DCNative' ) !== -1,
				isCashstar = window.location.host.indexOf( ".cashstar.com" ) !== -1;

			return ( !isConsumerApp || ( isConsumerApp && !isCashstar ) );

		}() );

		let perimeterXEnabled = true;

		if ( _T.mboxEnabled ) {
			at.init();
		}

		if ( perimeterXEnabled && !( window._pxAppId ) && !isOverlay() ) {
			perimeterx.init();
		}
	}

	window._T.deployments.sync = {
		build: BUILD_TARGET,
		version: CODE_VERSION,
		ts: '2024-03-25T16:21:34.607Z',
		host: getAnalyticsHost(),
		visitorAPI : ( Visitor ? Visitor.version : "na" ),
		visitorAPIisFromSync: visitorAPIisFromSync,
		atjs : ( window.adobe && window.adobe.target ? window.adobe.target.VERSION : "na" )
	};

} )();
